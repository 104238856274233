/**
 * plugins/vuetify.js
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { addIcon } from "@iconify/vue";

// Composables
import { createVuetify } from "vuetify";
import { VCalendar } from "vuetify/labs/VCalendar";

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
    VCalendar,
  },
  theme: {
    themes: {
      light: {
        colors: {
          primary: "#1867C0",
          secondary: "#5CBBF6",
          chat: "#B2C7DA",
          "light-grey": "#F5F5F5",
          "light-grey-container": "#E4E4E4",
          "low-text": "#595959",
          info: "#DFEAF4",
          "info-container": "#F5F9FC",
          alert: "#FEC0B7",
          "alert-container": "#FFECE9",
          "on-alert": "#000000",
          accent: "#f7eecd",
          "accent-container": "#fcf9eb",
          "on-accent": "#000000",
          error: "#C60000",
          "kakao-yellow": "#FAE100",
          confirm: "#3183FF",
          "on-confirm": "#FFFFFF",
          select: "#D3E5FF",
          "on-select": "#000000",
          cancel: "#FFF5EB",
          "on-cancel": "#920900",
          confirmed: "#4CAF50",
          "on-confirmed": "#FFFFFF",
        },
      },
      dark: false,
    },
  },
});

addIcon("@custom:md:star", {
  body: `<path d="M166.645 85.3959L167.614 87.0581L169.494 87.4654L227.164 99.9598C227.164 99.9599 227.164 99.9599 227.164 99.9599C228.687 100.291 230.096 101.015 231.251 102.06C232.407 103.106 233.268 104.436 233.75 105.918C234.231 107.4 234.315 108.982 233.994 110.507C233.674 112.032 232.959 113.445 231.922 114.607C231.921 114.608 231.921 114.608 231.921 114.608C231.921 114.608 231.921 114.608 231.921 114.609L192.609 158.606L191.327 160.041L191.521 161.955L197.471 220.658L197.471 220.659C197.629 222.211 197.376 223.776 196.74 225.2C196.103 226.624 195.104 227.855 193.842 228.772C192.58 229.689 191.1 230.258 189.549 230.424C187.999 230.59 186.432 230.346 185.005 229.717L185.004 229.716L131.008 205.916L129.246 205.14L127.485 205.916L73.4891 229.716L73.4883 229.717C72.0613 230.346 70.4943 230.59 68.9435 230.424C67.3928 230.258 65.9127 229.689 64.651 228.772C63.3893 227.855 62.3901 226.624 61.7533 225.2C61.1165 223.776 60.8642 222.211 61.0218 220.659L61.0219 220.658L66.9719 161.955L67.166 160.04L65.8834 158.605L26.5709 114.618L26.5697 114.616C25.5301 113.454 24.8135 112.039 24.4916 110.514C24.1697 108.988 24.2536 107.404 24.735 105.921C25.2164 104.438 26.0785 103.107 27.2352 102.061C28.3918 101.015 29.8026 100.291 31.3267 99.9604L88.9991 87.4654L90.8792 87.0581L91.8475 85.3959L121.544 34.4177C122.329 33.0709 123.453 31.9535 124.805 31.1768C126.156 30.4002 127.688 29.9914 129.246 29.9914C130.805 29.9914 132.337 30.4002 133.688 31.1768C135.04 31.9535 136.164 33.0709 136.949 34.4177L166.645 85.3959Z" fill="#F5E12D" stroke="#CB7A02" stroke-width="8.73288"/>`,
  width: 255,
  height: 255,
});

addIcon("@custom:md:star-empty", {
  body: `<path d="M168.491 85.8501L167.826 84.7081L138.129 33.7297C137.224 32.1765 135.928 30.8878 134.369 29.9921C132.81 29.0964 131.044 28.625 129.246 28.625C127.449 28.625 125.682 29.0964 124.124 29.9921C122.565 30.8878 121.269 32.1765 120.363 33.7297L90.6668 84.7081L90.0015 85.8501L88.7098 86.13L31.0373 98.625C29.2797 99.0058 27.6526 99.841 26.3187 101.047C24.9847 102.253 23.9905 103.788 23.4353 105.499C22.8801 107.21 22.7833 109.036 23.1546 110.796C23.5259 112.555 24.3523 114.187 25.5512 115.527L25.5521 115.528L64.8646 159.516L65.7458 160.502L65.6124 161.817L59.6624 220.521L59.6624 220.521C59.4807 222.31 59.7715 224.116 60.506 225.758C61.2404 227.4 62.3927 228.82 63.8478 229.877C65.3029 230.935 67.0099 231.592 68.7983 231.783C70.5867 231.974 72.394 231.693 74.0397 230.967L74.0402 230.967L128.036 207.167L129.246 206.633L130.456 207.167L184.453 230.967L184.453 230.967C186.099 231.693 187.906 231.974 189.695 231.783C191.483 231.592 193.19 230.935 194.645 229.877C196.1 228.82 197.252 227.4 197.987 225.758C198.721 224.116 199.012 222.31 198.831 220.521L198.83 220.521L192.88 161.817L192.747 160.502L193.628 159.516L232.94 115.519C234.137 114.178 234.961 112.547 235.331 110.789C235.702 109.03 235.604 107.205 235.049 105.496C234.494 103.787 233.501 102.253 232.168 101.047C230.836 99.8419 229.211 99.0068 227.456 98.625L168.491 85.8501ZM168.491 85.8501L169.783 86.13L227.454 98.6246L168.491 85.8501Z" fill="#FFFFFF00" stroke="#696969" stroke-width="6"/>`,
  width: 255,
  height: 255,
});

addIcon("@custom:ddtalk:logo", {
  body: `<path d="M143.789 45.2701H119.292V53.7081H131.949C135.487 53.7081 138.482 53.7081 140.795 53.572C142.973 53.572 145.014 53.2998 146.784 52.8915V70.176C145.15 70.7204 142.973 71.1287 140.251 71.1287C137.393 71.4009 134.535 71.4009 131.541 71.4009H108.268V76.9809H152.228V95.3541H39.6746V76.9809H83.6342V71.4009H45.1186V27.5774H91.1196V45.2701H68.2552V53.7081H90.7113V64.0516H95.4747V27.5774H143.789V45.2701ZM122.014 136.456V119.988H44.8464V100.934H145.831V136.456H122.014ZM250.714 63.9155C253.028 64.0516 255.342 64.0516 257.519 63.9155C259.561 63.9155 261.466 63.9155 263.372 63.6433C265.141 63.6433 266.91 63.3711 268.543 63.0989V76.8448C266.094 77.6614 262.691 78.2057 258.472 78.3418C254.117 78.614 249.354 78.614 244.182 78.614H229.347V83.3775H273.307V99.7092H160.754V83.3775H204.713V78.614H167.423V26.7608H266.502V41.4593H190.559V45.2701H265.141V59.9686H190.559V63.9155H250.714ZM243.093 136.456V123.39H165.926V105.017H266.91V136.456H243.093Z" fill="#320000"/>`,
  width: 314,
  height: 174,
});

addIcon("@custom:ddtalk:logo-long", {
  body: `<path d="M61.9128 52.6082C61.9128 35.0516 54.9718 23.6194 40.8176 23.6194H24.622V81.597H40.8176C54.9718 81.597 61.9128 70.437 61.9128 52.6082ZM84.7772 52.6082C84.6411 83.7746 69.126 102.964 35.2376 103.1H0.804891V2.1159H35.2376C69.126 2.252 84.6411 21.5779 84.7772 52.6082ZM156.942 52.6082C156.942 35.0516 150.001 23.6194 135.847 23.6194H119.651V81.597H135.847C150.001 81.597 156.942 70.437 156.942 52.6082ZM179.806 52.6082C179.67 83.7746 164.155 102.964 130.267 103.1H95.8341V2.1159H130.267C164.155 2.252 179.67 21.5779 179.806 52.6082ZM186.1 2.1159H262.995V24.4359H236.32V103.1H212.095V24.4359H186.1V2.1159ZM313.028 103.1V96.5678C308.537 101.195 301.46 104.053 293.566 104.053C273.696 104.053 257.636 91.8044 258.317 65.2653C259.133 38.454 276.01 25.933 294.519 26.7496C302.413 27.1579 308.945 30.6964 313.028 35.0516V28.2467H336.573V103.1H313.028ZM296.424 83.2302C304.454 83.2302 310.578 79.4195 312.212 71.1175C312.348 70.5731 312.484 67.9872 312.484 67.4429C312.484 54.6497 305.679 47.5726 296.969 47.5726C289.619 47.1643 281.862 53.0165 281.862 65.2653C281.862 77.1058 288.122 83.2302 296.424 83.2302ZM347.663 0.0744318H371.344V103.1H347.663V0.0744318ZM406.033 80.5082V103.1H382.352V2.1159H406.033V50.1584L426.448 28.2467H454.484L427.536 57.0994L458.022 103.1H430.394L411.749 74.2477L406.033 80.5082Z" fill="#320000"/>`,
  width: 459,
  height: 104,
});
