<template>
  <router-view v-slot="{ Component }">
    <component ref="mainView" :is="Component" />
  </router-view>
  <v-snackbar v-model="snackbar" timeout="2000">
    한번 더 뒤로가기를 하시면 앱이 종료됩니다.
  </v-snackbar>
</template>

<script setup>
//
import { watchEffect, onMounted, ref } from "vue";
import { SplashScreen } from "@capacitor/splash-screen";
import { App } from "@capacitor/app";
import { useRouter } from "vue-router";

const mainView = ref();
const snackbar = ref();

onMounted(() => {
  const router = useRouter();
  App.addListener("backButton", (data) => {
    if (!mainView.value?.backAction || !mainView.value.backAction()) {
      if (data.canGoBack) {
        router.go(-1);
      } else {
        quitApp();
      }
    }
  });
});

function quitApp() {
  App.minimizeApp();
}

watchEffect(async () => {
  const onDeviceReady = () => {
    SplashScreen.hide();
  };

  // When `deviceready` fires, call `onDeviceReady` to hide the splash screen.
  document.addEventListener("deviceready", onDeviceReady);

  // When the component unmounts, remove the event listener
  return () => {
    document.removeEventListener("deviceready", onDeviceReady);
  };
});
</script>

<style lang="css">
:root {
  overscroll-behavior: none;
}
</style>
