import { initializeApp } from "firebase/app";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyDN7Zx1l0NH6ZW9M5QbI5dfb3JR_fgqoC8",
  authDomain: "ddtalk-25ef2.firebaseapp.com",
  projectId: "ddtalk-25ef2",
  storageBucket: "ddtalk-25ef2.appspot.com",
  messagingSenderId: "65912133913",
  appId: "1:65912133913:web:013e561cf18271b6510d88",
  measurementId: "G-EBJ932LV8E",
};

export const firebaseApp = initializeApp(firebaseConfig);
export const localFunction = false;

if (process.env.NODE_ENV === "production") {
  import("firebase/analytics")
    .then(({ getAnalytics }) => {
      getAnalytics(firebaseApp);
    })
    .catch((err) => {
      console.log("Analytics not loaded", err);
    });
}

// const db = getFirestore();
// connectFirestoreEmulator(db, '127.0.0.1', 8080);

if (localFunction) {
  const functions = getFunctions(firebaseApp, "asia-northeast3");
  connectFunctionsEmulator(functions, "127.0.0.1", 5001);
}
