import { defineStore } from "pinia";
import { ref } from "vue";
import * as amplitude from "@amplitude/analytics-browser";
import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { Capacitor } from "@capacitor/core";
import { useConstant } from "@/utils/constant";

const isDev = import.meta.env.MODE === "development";
const isTest = import.meta.env.MODE === "test";

export const useAnalytics = defineStore("analytics", () => {
  const isInitialized = ref(false);

  function initAnalytics(to) {
    if (isInitialized.value || !to) return;
    const isPlainUser = to.matched.some((route) =>
      ["Links", "DDTalkOrder", "AdjustTime"].includes(route.name)
    );
    const sdkKey =
      isDev || isTest
        ? "46e15a1ef0f104fc369724163351fd98"
        : isPlainUser
        ? "b424cf90bb6598a3bcb8bc1ac1a2faf7"
        : "32405797ae53da93cab28ebb02a6fe52";
    amplitude.init(sdkKey, {
      defaultTracking: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: true,
        fileDownloads: false,
      },
    });
    const { isJdelingApp } = useConstant();
    if (!isDev && !isTest && !isJdelingApp && !isPlainUser) {
      const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 0.08 });
      amplitude.add(sessionReplayTracking);
    }
    isInitialized.value = true;
  }

  function setUserId(id) {
    const identifyEvent = new amplitude.Identify();
    amplitude.identify(identifyEvent);
    amplitude.setUserId(id);
    amplitude.setOptOut(false);
  }

  function setOptOut(value) {
    // if (Capacitor.isNativePlatform()) {
    //   AppsFlyer.stop(true);
    // }
    amplitude.setOptOut(value);
  }

  function setUserProperties(properties) {
    // if (Capacitor.isNativePlatform()) {
    //   AppsFlyer.setAdditionalData(properties);
    // }
    const identifyEvent = new amplitude.Identify();
    Object.entries(properties).forEach(([key, value]) => {
      identifyEvent.set(key, value);
    });
    amplitude.identify(identifyEvent);
  }

  function logEvent(name, properties) {
    // if (Capacitor.isNativePlatform()) {
    //   AppsFlyer.logEvent(name, properties);
    // }
    amplitude.track(name, properties);
  }

  return {
    initAnalytics,
    setUserId,
    setUserProperties,
    logEvent,
    setOptOut,
    isInitialized,
  };
});
