export function useConstant() {
  const isJdelingApp = import.meta.env.MODE === "jdeling";
  const appUrl = isJdelingApp
    ? "https://app.jdeling.com"
    : "https://app.ddtalk.co";

  const homepageUrl = isJdelingApp
    ? "https://jdeling.com"
    : "https://landing.ddtalk.co";

  const iosAppUrl = isJdelingApp
    ? ""
    : "https://apps.apple.com/us/app/id6451443247";
  const aosAppUrl = isJdelingApp
    ? ""
    : "https://play.google.com/store/apps/details?id=co.ddtalk";

  return {
    isJdelingApp,
    appUrl,
    homepageUrl,
    iosAppUrl,
    aosAppUrl,
  };
}
