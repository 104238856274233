// Utilities
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";
import { getApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  onAuthStateChanged,
  initializeAuth,
  indexedDBLocalPersistence,
  createUserWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import {
  doc,
  collection,
  getDocs,
  query,
  where,
  limit,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useFirestore, useDocument, firestoreDefaultConverter } from "vuefire";
import { useAnalytics } from "@/store/analytics";
import { Capacitor } from "@capacitor/core";

export const useUserStore = defineStore("user", () => {
  const db = useFirestore();
  const functions = getFunctions(getApp(), "asia-northeast3");
  const userRx = ref(null);
  const auth =
    Capacitor.getPlatform() === "ios"
      ? initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        })
      : getAuth();
  const nextRoute = ref(null);
  const analytics = useAnalytics();
  const loaded = ref(false);

  const userDoc = computed(() =>
    userRx.value?.uid
      ? doc(db, "users", userRx.value.uid).withConverter({
          toFirestore: firestoreDefaultConverter.toFirestore,
          fromFirestore: (snapshot, options) => {
            const data = firestoreDefaultConverter.fromFirestore(
              snapshot,
              options
            );
            // if the document doesn't exist, return null
            if (!data) return null;
            // add anything custom to the returned object
            data.metadata = snapshot.metadata;
            return { ...data, id: snapshot.id };
          },
        })
      : null
  );
  const userDataRx = useDocument(userDoc);

  const callDeleteDemo = httpsCallable(functions, "callDeleteDemo");
  const callCreateUser = httpsCallable(functions, "callCreateUser");
  const callAssignDemo = httpsCallable(functions, "callAssignDemo");
  const callAuthKakao = httpsCallable(functions, "callAuthKakao");
  const callLoginKakao = httpsCallable(functions, "callLoginKakao");

  const showShopList = computed(
    () => isSuper.value || isAdmin.value || userDataRx.value?.shops
  );

  onAuthStateChanged(auth, (user) => {
    if (user) {
      loaded.value = false;
      userRx.value = user;
      if (user.email.startsWith("test") && user.email.endsWith("ddtalk.co")) {
        return;
      }
      analytics.setUserId(user.email ?? user.uid);
    } else {
      userRx.value = null;
      loaded.value = true;
    }
  });

  watch(
    userDataRx,
    (newVal) => {
      if (newVal) {
        analytics.setUserProperties(newVal);
        loaded.value = true;
      }
    },
    {
      immediate: true,
      deep: true,
    }
  );

  function assureLoaded() {
    return new Promise((resolve) => {
      // 먼저 상태를 한 번 체크
      if (loaded.value) {
        resolve(); // 조건이 이미 만족하면 바로 resolve
        return;
      }

      // 조건이 만족되지 않은 경우에만 watch 시작
      const unwatch = watch(loaded, (newVal) => {
        if (newVal) {
          unwatch(); // 조건이 만족되면 감시 종료
          resolve();
        }
      });
    });
  }

  async function getUnassignedDemo(tickets) {
    const shops = await getDocs(
      query(collection(db, "shop"), where("assigned", "==", false), limit(1))
    );
    if (shops.docs.length == 0) {
      if (tickets == 0) return null;
      await new Promise((r) => setTimeout(r, 100));
      return getUnassignedDemo(tickets - 1);
    }
    const demoShop = shops.docs[0];
    return demoShop;
  }

  async function assignDemo() {
    const shop = await getUnassignedDemo(10);
    if (!shop) return null;
    const uid = shop.get("uid");
    const userSnapshot = await getDoc(doc(db, "users", uid));
    const userData = userSnapshot.data();
    const email = userData.email;
    const password = userData.tempPassword;
    const loginResult = await login(email, password);
    if (loginResult === "success") {
      callAssignDemo({ shopId: shop.id });
      return shop.id;
    } else {
      return null;
    }
  }

  async function removeDemo(shopId) {
    await callDeleteDemo({ shopId: shopId });
  }

  async function login(email, password) {
    try {
      loaded.value = false;
      const credential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      userRx.value = credential.user;
      return "success";
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      return errorCode;
    }
  }

  async function loginOrSignUpKakao(code, signUpId, redirectUri) {
    const res = await callAuthKakao({ code, signUpId, redirectUri });
    if (res.data?.firebase_token) {
      loaded.value = false;
      const token = res.data.firebase_token;
      const credential = await signInWithCustomToken(auth, token);
      userRx.value = credential.user;
      return res.data.shop_id
        ? { status: "success", new_shop_id: res.data.shop_id }
        : res.data.already_assigned
        ? { status: "redirect" }
        : { status: "success" };
    } else {
      const error = res.data?.error;
      return { status: "failed", error };
    }
  }

  async function loginKakao(code, redirectUri) {
    const res = await callLoginKakao({ code, redirectUri });
    if (res.data?.firebase_token) {
      loaded.value = false;
      const token = res.data.firebase_token;
      const credential = await signInWithCustomToken(auth, token);
      userRx.value = credential.user;
      return {
        status: "success",
      };
    } else {
      const error = res.data?.error;
      return { status: "failed", error };
    }
  }

  async function userSignUp(email, password, name, signUpId) {
    try {
      loaded.value = false;
      const credential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      await callCreateUser({
        uid: credential.user.uid,
        email: email,
        name: name,
        signup: signUpId,
      });
      await assureLoaded();
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error);
      console.log(errorCode);
      console.log(errorMessage);
      return errorCode;
    }
  }

  async function logout() {
    try {
      await signOut(auth);
      loaded.value = false;
      userRx.value = null;
      userDataRx.value = null;
      return true;
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      return errorCode;
    }
  }

  function isValidUser(shopData) {
    return (
      userDataRx.value?.shop === shopData?.id ||
      (isAdmin.value && userDataRx.value.group === shopData?.group) ||
      (userDataRx.value?.shops && userDataRx.value.shops.includes(shopData?.id))
    );
  }

  function getShopId() {
    return userDataRx.value?.shop;
  }

  function setNextRoute(route) {
    nextRoute.value = route;
  }

  function getNextRoute() {
    return nextRoute.value;
  }

  function resolveRoute() {
    nextRoute.value = null;
  }
  const group = computed(() => userDataRx.value?.group);
  const isAdmin = computed(() => userDataRx.value?.admin === true);
  const isSuper = computed(() => userDataRx.value?.super_admin === true);
  // const isDemoUser = computed(() => userDataRx.value?.demo === true);
  const isReviewUser = computed(
    () => userDataRx.value?.email === "ddtalk@ddtalk.co"
  );

  return {
    userDataRx,
    userRx,
    assignDemo,
    userSignUp,
    login,
    loginKakao,
    loginOrSignUpKakao,
    logout,
    isValidUser,
    setNextRoute,
    getNextRoute,
    resolveRoute,
    group,
    showShopList,
    isAdmin,
    isSuper,
    getShopId,
    assureLoaded,
    removeDemo,
    isReviewUser,
  };
});
