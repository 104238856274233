// Utilities
import { defineStore, storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useShopStore } from "@/store/shop";
import dayjs from "dayjs";

export const useGlobalStore = defineStore("global", () => {
  const shopStore = useShopStore();
  const { isDemo } = storeToRefs(shopStore);
  const today = ref();

  watch(
    isDemo,
    (newVal) => {
      if (newVal === undefined) return;
      if (newVal) {
        today.value = dayjs("2023-10-27");
      } else {
        today.value = dayjs();
      }
    },
    {
      immediate: true,
    }
  );

  return {
    today,
  };
});
